import { Component, inject } from '@angular/core';
import { ChatUsers } from '../../../shared/model/chat.model';
import { ChatService } from '../../../shared/services/chat.service';
import { InventarioService } from 'src/app/services/inventario.service';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { CodigosBusqueda } from '../../model/inventario.model';
import { Subject, switchMap, tap } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Sucursales } from '../../model/sucursales.model';

@Component({
  selector: 'app-menu-consult-price',
  templateUrl: './menu-consult-price.component.html',
  styleUrl: './menu-consult-price.component.scss'
})
export class MenuConsultPriceComponent {
  private fb = inject(FormBuilder);
  private catalogo = inject(CatalogoService);
  private usuario = inject(UsuarioService);

  public users: ChatUsers[] = []
  public searchUsers: ChatUsers[] = []
  public sucs: Sucursales[];
  public notFound: boolean = false
  public searchText: string = ''

  public filtroForm: FormGroup = this.fb.group({
    rbOpcion: [{ value: 'codigo', disabled: false }],
  });

  codigos: CodigosBusqueda[];
  idSuc: any;
  codigo$ = new Subject<string>();
  nombreSuc: string;
  ultimoCodigo: string;
  public menu_consult_price: boolean;

  constructor(private chatService: ChatService, private inventario: InventarioService) {
    this.chatService.getUsers().subscribe(users => {
      this.searchUsers = users
      this.users = users
    })

    this.changeCodigo();
  }

  ngOnInit() {
    if (this.filtroForm.get('rbOpcion')) {
      const rbOpcion = this.filtroForm.get('rbOpcion');
      if (rbOpcion) {
        rbOpcion.valueChanges.subscribe(() => {
          if (this.ultimoCodigo) {
            this.codigo$.next(this.ultimoCodigo);
          }
        });
      }
    }
    this.sucs = this.usuario.user()!.sucursales;
    const sucursal = this.sucs.find(sucursal => sucursal.idSuc !== 0);
    if (sucursal) {
      this.idSuc = sucursal.idSuc;
      this.nombreSuc = sucursal.nombre;
    }
  }

  changeCodigo() {// Cada vez que el valor de codigo$ cambia, cancela la solicitud anterior y comienza una nueva
    this.codigo$.pipe(
      tap(codigo => this.ultimoCodigo = codigo),
      switchMap((codigo) => {
        return this.catalogo.currentIdSuc.pipe(
          tap(idSucCatalogo => {
            if (idSucCatalogo) {
              const sucursalCatalogo = this.sucs.find(sucursal => sucursal.idSuc === idSucCatalogo);
              if (sucursalCatalogo) {
                this.idSuc = sucursalCatalogo.idSuc;
                this.nombreSuc = sucursalCatalogo.nombre;
              }
            }
          }),
          switchMap(() => {
            const rbOpcionValue = this.filtroForm.get('rbOpcion')?.value;
            return this.inventario.getConsPrecios(codigo, this.idSuc, rbOpcionValue);
          })
        );
      })
    ).subscribe((response) => {
      this.codigos = response.registers;
      this.checkSearchResultEmpty(this.codigos);
    });
  }

  searchCodigo(codigo: any) {
    if (!codigo || codigo.length < 3) {
      this.codigos = [];
      this.ultimoCodigo = '';
      return;
    }
    codigo = codigo.toLowerCase();
    this.ultimoCodigo = codigo;
    this.codigo$.next(codigo);
  }

  checkSearchResultEmpty(codigos: CodigosBusqueda[]) {
    if (!codigos || !codigos.length)
      this.notFound = true;
    else
      this.notFound = false;
  }

  closeSidebar() {
    this.catalogo.hideSidebar();
    this.codigos = [];
    this.searchText = '';
    this.ultimoCodigo = '';
  }
}

