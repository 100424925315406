import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, of, map } from 'rxjs';
import { Almacen, AltaCodigo, CodigosBusqueda, ConfirmationResponse, ConteoCodigo, ConteoDetalle, ConteoFiltro, ConteoResponse, Inventario } from '../shared/model/inventario.model';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../shared/services/notification.service';

@Injectable({
  providedIn: "root",
})
export class InventarioService {
  private http = inject(HttpClient);
  private notificationService = inject(NotificationService);
  public sucursal: string | null = null;

  public lst_alms: Almacen[] = [
    { id: 1, idTipoInventario: 0, nombre: "DIS" },
    { id: 2, idTipoInventario: 4, nombre: "EXH" },
  ];

  constructor() {}

  getInventario(idSuc: number): Observable<Inventario[] | []> {
    const url = `${environment.url}inventario/GetInventarios?IdSuc=${idSuc}`;
    return this.http.get<Inventario[]>(url).pipe(catchError(() => of([])));
  }

  getConteos(idInv: number): Observable<Inventario[] | []> {
    const url = `${environment.url}inventario/GetConteos?IdInventario=${idInv}`;
    return this.http.get<Inventario[]>(url).pipe(catchError(() => of([])));
  }

  getConteo(conteo: ConteoFiltro): Observable<ConteoResponse | null> {
    const url = `${environment.url}inventario/GetConteo?PageSize=${conteo.pageSize}&PageIndex=${conteo.pageIndex}&IdSuc=${conteo.idSuc}&IdConteo=${conteo.idConteo}&IdMarca=${conteo.idMarca}&IdClase=${conteo.idClase}&Ubicacion=${conteo.ubicacion}&Contado=${conteo.contado}`;
    return this.http.get<ConteoResponse>(url).pipe(catchError(() => of(null)));
  }

  getConteoCodigo(conteo: ConteoCodigo): Observable<ConteoResponse | null> {
    const params: { [param: string]: string } = {};

    if (conteo.pageSize) params["PageSize"] = conteo.pageSize.toString();
    if (conteo.pageIndex) params["PageIndex"] = conteo.pageIndex.toString();
    if (conteo.idSuc) params["IdSuc"] = conteo.idSuc.toString();
    if (conteo.idConteo) params["IdConteo"] = conteo.idConteo.toString();
    if (conteo.codigo) params["Codigo"] = conteo.codigo.toString();
    if(conteo.tipoBusqueda) params["TipoBusqueda"] = conteo.tipoBusqueda;

    const url = `${environment.url}inventario/GetConteoCodigo`;
    return this.http
      .get<ConteoResponse>(url, { params })
      .pipe(catchError(() => of(null)));
  }

  AltaCodigo(codigo: AltaCodigo) {
    const url = `${environment.url}inventario/ConteoDetalleAlta`;
    return this.http.post<ConfirmationResponse>(url, codigo).pipe(
      map((resp) => {
        this.notificationService.showSuccess(resp.mensaje);
      })
    );
  }

  ConteoDetalleInsertar(conteo: ConteoDetalle) {
    const url = `${environment.url}inventario/ConteoDetalleInsertar`;
    return this.http.post<ConfirmationResponse>(url, conteo).pipe(
      map((resp) => {
        this.notificationService.showSuccess(resp.mensaje);
      })
    );
  }

  putConteoDetalleModificar(conteo: ConteoDetalle) {
    const url = `${environment.url}inventario/ConteoDetalleModificar/${conteo.idConteoDetalle}`;
    return this.http.put<ConfirmationResponse>(url, conteo).pipe(
      map((resp) => {
        this.notificationService.showSuccess(resp.mensaje);
      })
    );
  }

  putConteoDetalleEliminar(conteo: ConteoDetalle) {
    const url = `${environment.url}inventario/ConteoDetalleEliminar`;
    return this.http.put<ConfirmationResponse>(url, conteo).pipe(
      map((resp) => {
        this.notificationService.showSuccess(resp.mensaje);
      })
    );
  }

  getConsPrecios(
    codigo: string,
    idSuc: number,
    opcion: string
  ): Observable<any> {
    const url = `${environment.url}buscador/GetBuscador_Codigos?Opcion=${opcion}&Busqueda=${codigo}&IdSuc=${idSuc}`;
    return this.http.get<CodigosBusqueda>(url).pipe(catchError(() => of([])));
  }
}
