<!-- Right sidebar Start-->


<div class="container p-0">
    <div class="modal-header p-l-20 p-r-20">
        <div class="col-sm-8 p-0">
            <h6 class="modal-title font-weight-bold">CONSULTA PRECIOS</h6>
            <p>{{ nombreSuc }}</p>
        </div>
        <div type="button" class="col-sm-4 text-end p-0" (click)="closeSidebar()"><i class="me-2" data-feather="x"></i></div>
    </div>

    <form [formGroup]="filtroForm">
        <div class="container">
            <div class="form-group  m-checkbox-inline mb-0 custom-radio-ml" role="group" #rbOpcion>
                <div class="radio radio-primary">
                    <input formControlName="rbOpcion" type="radio" id="codigo" [value]="'codigo'">
                    <label for="codigo" class="mb-0">Código</label>
                </div>
                <div class="radio radio-primary">
                    <input formControlName="rbOpcion" type="radio" id="descripcion" [value]="'descripcion'">
                    <label for="descripcion" class="mb-0">Descripción</label>
                </div>
            </div>
        </div>
    </form>

</div>
<div class="friend-list-search mt-0">
    <input class="form-control" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
        (keyup)="searchCodigo(searchText)" type="text" placeholder="search"><i class="fa fa-search"></i>
</div>
<div class="p-l-30 p-r-30">
    <div class="chat-box">
        <div class="people-list friend-list custom-scrollbar">
            <ul class="list">
                <ng-container *ngFor="let codigo of codigos">
                    <li class="clearfix">
                        <a [routerLink]="['/chat/']">
                            <!--img class="rounded-circle user-image" [src]="user?.profile" alt=""-->
                            <!--div class="status-circle"
                                    [ngClass]="{'online' : user.online, 'offline' : !user.online}"></!--div-->
                            <div class="about">
                                <div class="name">{{codigo?.codigo}}</div>
                                <div class="status">{{codigo?.descripcion}}</div>
                                <div class="name">P3 = {{codigo?.p3 | currency}}</div>
                                <div class="name">Exist = {{codigo?.total}}</div>
                            </div>
                        </a>
                    </li>
                </ng-container>
                <ng-container *ngIf="notFound">
                    <div class="image-not-found">
                        <div class="">
                            <img src="assets/images/search-not-found.png" alt="">
                            <p class="mb-0">Lo sentimos, no hemos encontrado ningún resultado que coincida con esta
                                búsqueda.</p>
                        </div>
                    </div>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
<!-- Right sidebar Ends-->